import styled from "@xstyled/styled-components";

const Loading = styled.spanBox`
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  top: 46%;
  left: 46%;
  border-radius: 12px;
  background-color: #fff;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    animation: loader9g 3s ease-in-out infinite;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0px;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    animation: loader9d 3s ease-in-out infinite;
  }

  @keyframes loader9g {
    0% {
      left: -25px;
      background-color: rgba(255, 255, 255, 0.8);
    }
    50% {
      left: 0px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    100% {
      left: -25px;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  @keyframes loader9d {
    0% {
      left: 25px;
      background-color: rgba(255, 255, 255, 0.8);
    }
    50% {
      left: 0px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    100% {
      left: 25px;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
`;

export default function Loader({ ...props }) {
  return <Loading {...props} />;
}
