import { useAtom, useAtomValue } from "jotai";
import { userBalanceAtom } from "src/store";
import { claimModalsAtom } from "src/store/modals";
import { FlexBox } from "../Containers";
import Button from "../Button";
import { toast } from "react-hot-toast";

export default function ClaimTokens({ isPolygon }) {
  const { unclaimed, locked, unlocked } = useAtomValue(userBalanceAtom);
  const [claimModals, setClaimModals] = useAtom(claimModalsAtom);

  const toClaim = isPolygon ? unlocked : unclaimed;

  return (
    <FlexBox justify={isPolygon ? "space-around" : "center"} w="100%" my={20}>
      {isPolygon && (
        <ClaimButton
          onClick={() => {
            if (locked > 0) {
              setClaimModals({
                ...claimModals,
                open: true,
                lockedTokens: true,
              });
            } else {
              return toast.error("No locked tokens to claim");
            }
          }}
          text="Claim Locked"
        />
      )}
      <ClaimButton
        onClick={() => {
          if (toClaim > 0) {
            setClaimModals({
              ...claimModals,
              open: true,
              lockedTokens: false,
            });
          } else {
            return toast.error("No unclaimed tokens to claim");
          }
        }}
        text="Claim"
      />
    </FlexBox>
  );
}

const ClaimButton = ({ onClick, text }) => {
  return (
    <Button
      onClick={onClick}
      p="10px 20px"
      borderRadius={5}
      color="#fff"
      bg="rgba(80, 80, 80, 0.2)"
      hoverBg="transparent"
      border="1px solid transparent"
      hoverBorder="1px solid #fff">
      {text}
    </Button>
  );
};
