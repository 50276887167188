import Web3 from "web3";
import logError from "./logError";

export const switchToPolygon = async () => {
  const chainId = 137; // Polygon Mainnet

  if (window.ethereum && window.ethereum.networkVersion !== chainId) {
    const web3 = new Web3(window.ethereum);

    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: web3.utils.toHex(chainId) }],
      });
    } catch (error) {
      // Chain has not been added to MetaMask
      if (error.code === 4902) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Polygon Mainnet",
              chainId: web3.utils.toHex(chainId),
              nativeCurrency: {
                name: "MATIC",
                decimals: 18,
                symbol: "MATIC",
              },
              rpcUrls: ["https://polygon-rpc.com/"],
            },
          ],
        });
      }
    }
  }
};

export const switchToEthereum = async () => {
  const chainId = 1; // Ethereum Mainnet

  if (window.ethereum && window.ethereum.networkVersion !== chainId) {
    const web3 = new Web3(window.ethereum);

    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: web3.utils.toHex(chainId) }],
      });
    } catch (error) {
      logError({ error });
    }
  }
};
