import Link from "next/link";
import { useRouter } from "next/router";
import { footerItems } from "src/data/menus";
import styled, { Box } from "@xstyled/styled-components";
import { Container, FlexBox } from "@/UI/Containers";
import { Text } from "@/UI/Text";
import Icon from "@/UI/Icon";
import { Discord, LinkedIn, OpenSea, Twitter } from "@/UI/icons";
import Button from "@/UI/Button";
import { WofLogo } from "@/UI/icons/Landing";

const Icons = styled.divBox`
  display: flex;
  align-items: center;

  svg {
    overflow: visible;
  }

  #Twitter:hover {
    svg path {
      fill: #1da1f2;
    }
  }

  #Discord:hover {
    svg path {
      fill: #7289da;
    }
  }

  #OpenSea:hover {
    svg path {
      fill: #2081e2;
    }
  }

  #LinkedIn:hover {
    svg path {
      fill: #0072b1;
    }
  }
`;

const Menu = styled.ul`
  display: flex;
  list-style-type: none;
  list-style-position: inside;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin-top: 50px;

  li:nth-of-type(1) {
    margin-right: 60px;
  }
  li:nth-of-type(3) {
    margin-right: 85px;
  }

  @media (min-width: desktop) {
    flex-wrap: wrap;
    align-self: flex-start;
    margin-top: -35px;

    li {
      margin-right: 40px;
    }
  }
`;

export default function Footer(props) {
  const router = useRouter();

  return (
    <Box as="footer" position="relative" background="transparent" {...props}>
      <Container pb={100} zIndex={3}>
        <FlexBox
          dir={{ _: "column", desktop: "row" }}
          align="flex-start"
          w="100%"
        >
          <Link href="/" passHref>
            <Box as="a" pr={80} mt={{ _: 35, desktop: -35 }}>
              <Icon>
                <WofLogo fill="#707070" />
              </Icon>
            </Box>
          </Link>
          <Menu>
            {footerItems.map((column) => {
              return (
                <Box as="li" color="#fff" key={column.category} pb={50}>
                  {column.category}
                  <Box
                    as="ul"
                    listStyleType="none"
                    listStylePosition="inside"
                    pl={0}
                    mt={10}
                  >
                    {column.subnav.map((item) => {
                      return (
                        <Box
                          as="li"
                          listStyleType="none"
                          lineHeight={2}
                          key={item.title}
                        >
                          <Button
                            onClick={() => {
                              if (item.title == "Learn more") {
                                window.fbq("trackCustom", "learn");
                              }
                              router.push(item.path);
                            }}
                            p={0}
                            color="#808080"
                            hoverColor="#FEB806"
                          >
                            {item.title}
                          </Button>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Menu>

          <FlexBox
            dir="column"
            m={{ desktop: "-55px 0 0 0" }}
            justify="flex-start"
            align="flex-start"
          >
            <Text size="1rem" font="Regular" whiteSpace="nowrap">
              COMMUNITY
            </Text>
            <Icons spaceX={{ _: "1rem", desktop: "2rem" }}>
              <Icon id="Discord" aria="Discord logo">
                <a
                  href="https://discord.gg/WorldOfFreight"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Discord fill="#808080" />
                </a>
              </Icon>
              <Icon id="Twitter" aria="Twitter logo">
                <a
                  href="https://twitter.com/WorldOfFreight"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter fill="#808080" />
                </a>
              </Icon>
              <Icon id="OpenSea" aria="OpenSea logo">
                <a
                  href="https://opensea.io/collection/world-of-freight"
                  target="_blank"
                  rel="noreferrer"
                >
                  <OpenSea fill="#808080" />
                </a>
              </Icon>
              <Icon
                id="LinkedIn"
                aria="LinkedIn logo"
                transform="translateY(-6%) scale(1.1)"
              >
                <a
                  href="https://www.linkedin.com/company/wof-labs/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedIn fill="#808080" />
                </a>
              </Icon>
            </Icons>
            <Text size="1rem" font="Regular" whiteSpace="nowrap" mt={42}>
              CONTACT
            </Text>
            <Box
              as="a"
              href="mailto:info@worldoffreight.xyz"
              color="#808080"
              hoverColor="#FEB806"
              cursor="pointer"
            >
              info@worldoffreight.xyz
            </Box>
          </FlexBox>
        </FlexBox>
      </Container>
    </Box>
  );
}
