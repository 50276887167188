import Button from "../Button";

export default function GradientButtonBlue({
  onClick,
  children,
  disabled,
  ...rest
}) {
  return (
    <Button
      onClick={onClick}
      h={40}
      fontSize="1.125rem"
      color="#fff"
      backgroundImage="gradient-to-r"
      gradientTo="#18E1E2"
      gradientFrom="#242B8B"
      hoverBackground={!disabled && "transparent"}
      hoverOutline="1px solid #42C8C8"
      borderRadius={5}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );
}
