import { menu } from "src/data/menus";

export const getRacingMenuItems = ({ address, items }) => {
  return (
    items && items.filter((item) => (address ? item : item.title !== "My Area"))
  );
};

export const getFleetMenuItems = () => {
  const items = menu.find((item) => item.title === "Fleet");
  return items.subnav;
};
