import IconButton from "./IconButton";
import { CloseModal } from "../../UI/icons";

export default function DismissButton({ onClick, stroke, ...rest }) {
  return (
    <IconButton
      onClick={onClick}
      aria="Dismiss modal"
      position="absolute"
      top={{ _: 20, desktop: 32 }}
      right={{ _: 20, desktop: 32 }}
      transform={{ _: "scale(0.6)", desktop: "scale(0.8)" }}
      {...rest}
    >
      <CloseModal stroke={stroke} />
    </IconButton>
  );
}
