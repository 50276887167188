import { useRouter } from "next/router";
import GradientButtonBlue from "../buttons/GradientButtonBlue";
import { FlexBox } from "../Containers";
import { Text } from "../Text";

export default function MintSection({ ...rest }) {
  const router = useRouter();

  return (
    <FlexBox
      dir="column"
      justify="center"
      mb={{ _: "4rem", desktop: "10rem" }}
      {...rest}
    >
      <Text
        size="1.25rem"
        font="Regular"
        color="white"
        align="center"
        max={{ _: 300, sm_tablet: "initial" }}
      >
        MINT YOURSELF A VEHICLE FOR 0.08 ETH
      </Text>

      <GradientButtonBlue
        onClick={() => router.push("/mint")}
        p="6px 75px"
        fontSize="1rem"
      >
        Mint
      </GradientButtonBlue>
    </FlexBox>
  );
}
