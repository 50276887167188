import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  balanceViewAtom,
  menuAtom,
  userBalanceAtom,
  userWalletAtom,
} from "src/store";
import { walletModalAtom } from "src/store/modals";
import useLockBodyScroll from "hooks/UI/useLockBodyScroll";
import styled, { Box, css } from "@xstyled/styled-components";
import Menu from "./Menu";
import MobileMenuContainer from "./MobileMenu/MobileMenuContainer";
import Wallet from "../wallet";
import { Container, FlexBox } from "../Containers";
import Hamburger from "./Hamburger";
import Button from "../Button";
import GradientButtonBlue from "../buttons/GradientButtonBlue";
import Loader from "../Loader";
import Icon from "../Icon";
import { TokenLogo } from "../icons";

const MenuContainer = styled.nav`
  display: none;

  @media (max-height: 700px) {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  @media (min-width: desktop) {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
  }

  ${({ page }) =>
    page === "fleet" &&
    css`
      @media (min-width: desktop) {
        padding-top: 20px;
        padding-bottom: 70px;
      }
    `}
`;

const BalanceRow = styled.divBox`
  display: flex;
  align-items: center;
  padding-top: 28.5px;
  padding-bottom: 28.5px;
  color: ${({ hovered }) => (hovered ? "#feb806" : "#fff")};
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: #feb806;
  }
`;

export default function Navbar({ page, ...rest }) {
  const router = useRouter();

  const [{ address, provider, network }, setWallet] = useAtom(userWalletAtom);
  const { polygon, eth } = useAtomValue(userBalanceAtom);
  const [balanceView, setBalanceView] = useAtom(balanceViewAtom);
  const menuOpen = useAtomValue(menuAtom);

  const isFleet = page === "fleet";

  const balance = network === "polygon" ? polygon : eth;

  const disconnect = () => {
    setWallet({
      network: null,
      address: null,
      provider: "Metamask",
      text: "Connect Wallet",
    });
    localStorage.removeItem("walletconnect");
  };

  useLockBodyScroll(menuOpen);

  return (
    <FlexBox
      display={{ _: "flex", desktop: "block" }}
      as="header"
      dir="column"
      position="relative"
      zIndex={4}
      {...rest}
    >
      {/* Mobile navbar */}
      <Box display={{ _: "block", desktop: "none" }} pt={30}>
        <FlexBox>
          <Hamburger transform="scaleY(-1)" />
          <FlexBox position="relative" mx="auto">
            <Link href="/">
              <a>
                <Image
                  src="/logo.svg"
                  alt="Wof logo"
                  width={100}
                  height={65}
                  layout="fixed"
                />
              </a>
            </Link>
          </FlexBox>
          <GradientButtonBlue
            onClick={() => router.push("/mint")}
            position="absolute"
            right={16}
            h={35}
            px={20}
            fontSize="1rem"
          >
            Mint
          </GradientButtonBlue>
        </FlexBox>
        {!address && (
          <ConnectButton
            display={{ _: "inline-flex", desktop: "none" }}
            alignSelf="center"
            mt={16}
            color="#fff"
          />
        )}
        <MobileMenuContainer />
      </Box>

      {/* Desktop navbar */}
      <Container display={{ _: "none", desktop: "block" }} pb={0}>
        <MenuContainer page={page} aria-label="Main navigation">
          <FlexBox position="relative">
            <Link href="/" passHref>
              <Box
                as="a"
                w={{
                  desktop: isFleet ? 52 : 82,
                  lg_desktop: isFleet ? 72 : 112,
                }}
              >
                <Image
                  src="/logo.svg"
                  alt="Wof logo"
                  width="100%"
                  height="50%"
                  layout="responsive"
                />
              </Box>
            </Link>
          </FlexBox>
          <Menu />
          <FlexBox>
            <FlexBox
              display={{ _: "none", desktop: "flex" }}
              dir="column"
              justify="center"
              ml={{ desktop: -4, lg_desktop: 16 }}
              mr={{ desktop: 8, lg_desktop: 16 }}
            >
              {!address ? (
                <ConnectButton
                  display={{ _: "none", desktop: "block" }}
                  fontSize="1rem"
                />
              ) : (
                <>
                  {balance !== null ? (
                    <BalanceRow
                      hovered={balanceView.hovered}
                      onMouseEnter={() => {
                        if (network === "polygon" || network === "ethereum") {
                          setBalanceView({ ...balanceView, shown: true });
                        }
                      }}
                      onMouseLeave={() => {
                        setBalanceView({
                          ...balanceView,
                          shown: !!balanceView.hovered,
                        });
                      }}
                    >
                      Balance: {Number(balance).toFixed(2)}
                      <Icon
                        aria="WOF Token"
                        h={{ _: 30, desktop: 34 }}
                        ml={6}
                        transform="translateY(12%)"
                      >
                        <TokenLogo
                          fill={balanceView.shown ? "#feb806" : "#fff"}
                        />
                      </Icon>
                    </BalanceRow>
                  ) : (
                    <Box mr={32}>
                      <Loader />
                    </Box>
                  )}
                </>
              )}

              {balanceView.shown && <Wallet />}
            </FlexBox>

            <GradientButtonBlue
              onClick={() => router.push("/mint")}
              w={{ _: 100, desktop: 130 }}
              fontSize="1rem"
            >
              Mint
            </GradientButtonBlue>

            {provider === "WalletConnect" && (
              <Button
                onClick={disconnect}
                position="absolute"
                top={isFleet ? 106 : 132}
                right={{ _: -16, lg_desktop: 16 }}
                color="#fff"
                hoverColor="#feb806"
              >
                Disconnect
              </Button>
            )}
          </FlexBox>
        </MenuContainer>
      </Container>
    </FlexBox>
  );
}

const ConnectButton = ({ ...props }) => {
  const { text } = useAtomValue(userWalletAtom);
  const setShowWalletModal = useSetAtom(walletModalAtom);

  const connect = () => {
    if (text !== "Connect Wallet") return null;
    setShowWalletModal(true);
  };

  return (
    <Button
      onClick={connect}
      aria="Connect wallet"
      color="#fff"
      hoverColor="#FEB806"
      textAlign="center"
      disabled={text !== "Connect Wallet"}
      {...props}
    >
      {text}
    </Button>
  );
};
