import { useEffect } from "react";

export default function useLockBodyScroll(isShown) {
  useEffect(() => {
    if (isShown) {
      document.body.style.overflowY = "hidden";
    }
    return () => (document.body.style.overflowY = "visible");
  }, [isShown]);
}
