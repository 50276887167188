import { Fragment, useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "@xstyled/styled-components";
import useLockBodyScroll from "hooks/UI/useLockBodyScroll";

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #595959;
  opacity: 0.5;
  z-index: 500;
`;

const ModalWrapper = styled.divBox`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
`;

const ModalContent = styled.divBox`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 9px;
  color: #fff;
  background: #292f3c;
  /* overflow-y: auto; */
  z-index: 200;
`;

export default function Modal({ isShown, hide, children, width, ...rest }) {
  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.keyCode === 27 && isShown) hide();
    };

    if (typeof window !== "undefined") {
      document.addEventListener("keydown", onKeyDown, false);
      return () => document.removeEventListener("keydown", onKeyDown, false);
    }
  }, [isShown, hide]);

  useLockBodyScroll(isShown);

  const modal = (
    <Fragment>
      <Backdrop onClick={hide} />
      <ModalWrapper aria-modal tabIndex={-1} role="dialog" w={width}>
        <ModalContent {...rest}>{children}</ModalContent>
      </ModalWrapper>
    </Fragment>
  );

  return typeof window !== "undefined" && isShown
    ? createPortal(modal, document.body)
    : null;
}
