import Link from "next/link";
import Image from "next/image";
import { useAtom, useAtomValue } from "jotai";
import {
  balanceViewAtom,
  menuAtom,
  userBalanceAtom,
  userWalletAtom,
} from "src/store";
import styled, { Box } from "@xstyled/styled-components";
import MobileMenu from ".";
import Wallet from "@/UI/wallet";
import DismissButton from "@/UI/buttons/DismissButton";
import { FlexBox } from "@/UI/Containers";
import { Discord, LinkedIn, OpenSea, TokenLogo, Twitter } from "@/UI/icons";
import Icon from "@/UI/Icon";
import { Text } from "@/UI/Text";

export const MobileMenuWrap = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  background-color: #041a24;
  position: fixed;
  top: 0;
  left: ${({ open }) => (open ? 0 : "-110%")};
  z-index: 200;
  transition: 350ms;
`;

export const SocialIcons = styled.divBox`
  /* margin: ${({ connected }) => (connected ? "16px auto" : "68vh auto")}; */
  margin: 32px auto 0;
  width: 100%;
  text-align: center;

  svg {
    overflow: visible;
  }
`;

export const BalanceButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  width: 80vw;
  /* margin: 50vh auto 2rem; */
  margin-top: 16px;
  padding: 10px;
  font-family: "Lufga-Medium";
  background: #feb806;
  color: #181e22;
  border: none;
  border-radius: 5px;
`;

export default function MobileMenuContainer() {
  const { address, network } = useAtomValue(userWalletAtom);
  const { polygon, eth } = useAtomValue(userBalanceAtom);
  const [open, setOpen] = useAtom(menuAtom);
  const [balanceView, setBalanceView] = useAtom(balanceViewAtom);

  const balance = network === "polygon" ? polygon ?? 0 : eth ?? 0;

  return (
    <MobileMenuWrap open={open}>
      <DismissButton
        onClick={() => setOpen(false)}
        stroke="white"
        top={38}
        left={20}
        right="initial"
      />
      <FlexBox
        justify="center"
        w="100vw"
        mt="2rem"
        mb={{ _: "0", lg_mobile: "3rem" }}
      >
        <Link href="/">
          <a>
            <Image src="/logo.svg" alt="Wof logo" width={80} height={45} />
          </a>
        </Link>
      </FlexBox>

      <MobileMenu setOpen={setOpen} />

      {address && (
        <BalanceButton
          onClick={() => {
            if (network === "polygon" || network === "ethereum") {
              setBalanceView({ ...balanceView, shown: true });
            }
          }}
        >
          <Text as="span" size="1rem">
            Balance:
          </Text>
          <FlexBox as="span">
            <Box as="span" fontFamily="Lufga-Medium">
              {Number(balance).toFixed(2)}
            </Box>
            <Icon aria="WOF Token logo" h={20} ml={10} mb={5}>
              <TokenLogo fill="#181E22" />
            </Icon>
          </FlexBox>
        </BalanceButton>
      )}

      {balanceView.shown && <Wallet />}

      <SocialIcons connected={address} spaceX="1.2rem">
        <a
          href="https://discord.gg/WorldOfFreight"
          target="_blank"
          rel="noreferrer"
        >
          <Icon id="Discord" aria="Discord logo" position="relative" top={4}>
            <Discord fill="white" />
          </Icon>
        </a>
        <a
          href="https://twitter.com/WorldOfFreight"
          target="_blank"
          rel="noreferrer"
        >
          <Icon id="Twitter" aria="Twitter logo">
            <Twitter fill="white" />
          </Icon>
        </a>
        <a
          href="https://opensea.io/collection/world-of-freight"
          target="_blank"
          rel="noreferrer"
        >
          <Icon id="OpenSea" aria="OpenSea logo" position="relative" top={1}>
            <OpenSea fill="white" />
          </Icon>
        </a>
        <a
          href="https://www.linkedin.com/company/wof-labs/"
          target="_blank"
          rel="noreferrer"
        >
          <Icon id="LinkedIn" aria="LinkedIn logo" position="relative" top={-4}>
            <LinkedIn fill="white" />
          </Icon>
        </a>
      </SocialIcons>
    </MobileMenuWrap>
  );
}
