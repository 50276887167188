import formatNumber from "src/utils/formatNumber";
import { FlexBox } from "../Containers";
import { Text } from "../Text";

export default function BalanceRow({ title, value }) {
  return (
    <FlexBox w="100%" justify="space-between">
      {title}
      <Text
        m={0}
        display="inline-flex"
        alignItems="center"
        size="1rem"
        font="Medium"
      >
        {formatNumber(value)}
      </Text>
    </FlexBox>
  );
}
