import { useState } from "react";
import { useRouter } from "next/router";
import { useAtom, useAtomValue } from "jotai";
import { balanceViewAtom, userBalanceAtom, userWalletAtom } from "src/store";
import { fleetLinks } from "src/data/menus";
import formatAddress from "src/utils/formatAddress";
import { switchToEthereum, switchToPolygon } from "src/utils/switchNetwork";
import { Box } from "@xstyled/styled-components";
import BalanceRow from "./BalanceRow";
import ClaimTokens from "./ClaimTokens";
import TokenInfoDropdown from "./TokenInfoDropdown";
import { FlexBox } from "@/UI/Containers";
import Button from "@/UI/Button";
import IconButton from "@/UI/buttons/IconButton";
import { Text } from "@/UI/Text";
import Icon from "@/UI/Icon";
import { BackArrow, PolygonLogo, TokenLogo } from "@/UI/icons";
import { Info, Wallet as WalletIcon } from "@/UI/icons/Landing";

export default function Wallet() {
  const router = useRouter();

  const { address, network } = useAtomValue(userWalletAtom);
  const balance = useAtomValue(userBalanceAtom);
  const [balanceView, setBalanceView] = useAtom(balanceViewAtom);

  const [showInfo, setShowInfo] = useState(false);

  const isPolygon = network === "polygon";

  const isFleetPage = () => {
    return fleetLinks.some((path) => {
      return router.asPath.startsWith(path) || router.pathname.startsWith(path);
    });
  };

  return (
    <FlexBox
      dir="column"
      align="flex-start"
      position={{ _: "fixed", desktop: "absolute" }}
      top={{ desktop: isFleetPage() ? 70 : 100 }}
      left={{
        _: balanceView.shown ? 0 : "-110%",
        desktop: "69%",
        lg_desktop: "71%",
        xl_desktop: "76%",
      }}
      w={{ _: "100vw", desktop: 315 }}
      h={{ _: "100vh", desktop: "max-content" }}
      mt={{ desktop: 30 }}
      bg="#05151B"
      color="white"
      borderRadius={12}
      zIndex={10}
      whiteSpace="nowrap"
      onMouseEnter={() => {
        if (network === "polygon" || network === "ethereum") {
          setBalanceView({ shown: true, hovered: true });
        }
      }}
      onMouseLeave={() => {
        setBalanceView({ shown: false, hovered: false });
      }}
    >
      <FlexBox
        justify="space-between"
        w="100%"
        p={10}
        mb={{ _: 6, desktop: 0 }}
        borderRadius={{ desktop: "10px 10px 0 0" }}
        backgroundImage={
          isPolygon
            ? "linear-gradient(232deg, rgba(110,45,219,1) 0%, rgba(130,71,229,1) 100%)"
            : "linear-gradient(270deg, rgba(249,131,13,1) 0%, rgba(255,187,6,1) 100%)"
        }
        color="black"
        fontFamily="Lufga-Medium"
        fontSize="1rem"
      >
        <IconButton
          onClick={() => setBalanceView({ ...balanceView, shown: false })}
          display={{ desktop: "none" }}
        >
          <Icon aria="Dismiss balance view">
            <BackArrow />
          </Icon>
        </IconButton>
        <Text
          m={0}
          display="inline-flex"
          alignItems="center"
          size="1rem"
          font="Medium"
          color={isPolygon ? "#fff" : "#1F2533"}
        >
          <Icon aria={isPolygon ? "Polygon" : "Ethereum"} h={20} pr={7}>
            {isPolygon ? (
              <PolygonLogo fill="#fff" />
            ) : (
              <Box
                as="img"
                src="/assets/wallet/Ethereum_logo.png"
                alt="Ethereum"
                transform="translateY(-10%)"
              />
            )}
          </Icon>
          <span>{isPolygon ? "Polygon chain" : "Ethereum chain"}</span>
        </Text>
        <Button onClick={() => setShowInfo(!showInfo)} p={0}>
          <Icon aria="Info" h={25}>
            <Info stroke={showInfo ? "white" : "black"} />
          </Icon>
        </Button>
      </FlexBox>

      <FlexBox
        w="100%"
        justify="space-between"
        p="18px 0 8px"
        borderBottom="1px solid #2B3B41"
      >
        <Text
          display="inline-flex"
          alignItems="center"
          m={0}
          size="0.875rem"
          color={isPolygon ? "#9F6CF3" : "#FEB806"}
        >
          <Icon aria="Wallet" mx={10}>
            <WalletIcon stroke={isPolygon ? "#9F6CF3" : "#FEB806"} />
          </Icon>
          Wallet
        </Text>
        <Text m={0} pr={10} size="0.875rem">
          {formatAddress(address)}
        </Text>
      </FlexBox>

      <FlexBox dir="column" align="flex-start" w="100%" p="12px 18px 18px 18px">
        <BalanceRow
          title={
            <Text
              display="inline-flex"
              alignItems="center"
              m="0 0 0 -6px"
              size="1rem"
            >
              <Icon
                aria="WOF Token"
                mr={10}
                transform="scale(0.9) translateY(10%)"
              >
                <TokenLogo fill="#fff" />
              </Icon>
              Balance
            </Text>
          }
          value={isPolygon ? balance.polygon : balance.eth}
          color="#5C616C"
          size="0.875rem"
        />

        <Button
          as="a"
          href="https://info.uniswap.org/#/polygon/pools/0x9b1d03edc33482468463da4ca689915f3c40944f"
          target="_blank"
          rel="noopener noreferrer"
          alignSelf="center"
          my={22}
          p="10px 16px"
          border="1px solid #FEB806"
          borderRadius={5}
          color="#fff"
          hoverBackgroundImage="gradient-to-r"
          gradientTo="#F9830D"
          gradientFrom="#FFBB06"
        >
          Buy more Tokens
        </Button>

        <FlexBox
          dir="column"
          align="flex-start"
          w="100%"
          borderTop="1px solid #2B3B41"
        >
          <Text w="100%" m={0} size="0.875rem" pt={10} color="#5F6266">
            {isPolygon
              ? "Earnings from Leasing"
              : "Passive Earnings from Ownership"}
          </Text>
          <BalanceRow
            title={isPolygon ? "Locked" : "Unclaimed"}
            value={isPolygon ? balance.locked : balance.unclaimed}
            size="1rem"
          />
          {isPolygon && (
            <Text
              w="100%"
              m={isPolygon ? "none" : 0}
              pb={20}
              size="0.75rem"
              color="#5C616C"
              borderBottom="1px solid #2B3B41"
            >
              *Claiming locked tokens will result in a 35% fee
            </Text>
          )}
          {isPolygon && (
            <BalanceRow
              title={isPolygon ? "Unlocked" : "Unclaimed"}
              value={isPolygon ? balance.unlocked : balance.unclaimed}
              size="1rem"
            />
          )}

          <ClaimTokens isPolygon={isPolygon} />
          <Button
            onClick={() => (isPolygon ? switchToEthereum() : switchToPolygon())}
            alignSelf="center"
            p="6px 0 12px"
            fontSize="0.9375rem"
            color="#ddd"
            textDecoration="underline"
          >
            Switch to {isPolygon ? "Ethereum" : "Polygon"} chain
          </Button>
        </FlexBox>
      </FlexBox>

      {showInfo && <TokenInfoDropdown isPolygon={isPolygon} />}
    </FlexBox>
  );
}
