import {
  LeaderboardIcon,
  MyAreaIcon,
  NextToRaceIcon,
  ResultsIcon,
  UpcomingIcon,
} from "@/UI/icons/RacingArena";
import {
  FleetDrivers,
  GarageNew,
  JunkyardNew,
  MyFleetNew,
} from "@/UI/icons/Fleet";

export const menu = [
  {
    title: "Freight Run",
    path: "/freight-run",
  },
  {
    title: "Racing",
    path: "/racing-arena/upcoming",
    subnav: [
      {
        title: "Upcoming",
        path: "/racing-arena/upcoming",
        icon: <UpcomingIcon />,
      },
      {
        title: "Next To Race",
        path: "/racing-arena/next-to-race",
        icon: <NextToRaceIcon />,
      },
      {
        title: "Results",
        path: "/racing-arena/results",
        icon: <ResultsIcon />,
      },
      {
        title: "Leaderboard",
        path: "/racing-arena/leaderboard",
        icon: <LeaderboardIcon />,
      },
      {
        title: "My Area",
        path: "/racing-arena/my-area",
        icon: <MyAreaIcon />,
      },
    ],
  },
  {
    title: "Fleet",
    path: "/fleet",
    subnav: [
      {
        title: "My Fleet",
        path: "/fleet",
        icon: <MyFleetNew />,
      },
      { title: "Drivers", path: "/drivers", icon: <FleetDrivers /> },
      {
        title: "Garage",
        path: "/garage",
        icon: <GarageNew />,
      },
      {
        title: "Junkyard",
        path: "/junkyard",
        icon: <JunkyardNew />,
      },
    ],
  },
  {
    title: "Punks",
    path: "/freight-punks",
  },

  {
    title: "Marketplace",
    path: "/mint",
    subnav: [
      {
        title: "New Vehicles",
        path: "/mint",
      },
      {
        title: "Secondary Market",
        path: "https://opensea.io/collection/world-of-freight",
        external: true,
      },
      {
        title: "Leasing",
        path: "/leasing",
      },
    ],
  },
  {
    title: "Learn",
    path: "/about/utility",
    subnav: [
      {
        title: "Learn more",
        path: "/about/utility",
      },
      {
        title: "About Us",
        path: "/about",
      },
      {
        title: "Community",
        path: "/community",
      },
      {
        title: "$WOF Tokens",
        path: "/wof-token",
      },
      {
        title: "Wiki",
        path: "https://worldoffreight.gitbook.io/world-of-freight/",
        external: true,
      },
      {
        title: "How to Mint",
        path: "/how-to-mint",
      },
      {
        title: "NFT Worlds",
        path: "/nft-worlds",
      },
      {
        title: "AMA Input Form",
        path: "/ama-form",
      },
    ],
  },
  // {
  //   title: "Challenges",
  //   path: "announcement",
  //   button: true,
  // },
  {
    title: "Bridge",
    path: "/bridge",
  },
  // {
  //   title: "Supplain",
  //   path: "https://supplain.io/",
  //   external: true,
  // },
];

export const fleetMenu = [
  {
    name: "My Fleet",
    path: "/fleet",
    icon: <MyFleetNew stroke="#181E22" />,
  },
  {
    name: "Drivers",
    path: "/drivers",
    icon: <FleetDrivers stroke="#181E22" />,
  },
  {
    name: "Garage",
    path: "/garage",
    icon: <GarageNew stroke="#181E22" />,
  },
  {
    name: "Junkyard",
    path: "/junkyard",
    icon: <JunkyardNew stroke="#181E22" />,
  },
];

export const racingMenu = [
  {
    name: "Upcoming",
    path: "/racing-arena/upcoming",
    icon: <UpcomingIcon stroke="#181E22" />,
  },
  {
    name: "Starting",
    path: "/racing-arena/next-to-race",
    icon: <NextToRaceIcon stroke="#181E22" />,
  },
  {
    name: "Results",
    path: "/racing-arena/results",
    icon: <ResultsIcon stroke="#181E22" />,
  },
  {
    name: "My Area",
    path: "/racing-arena/my-area",
    icon: <MyAreaIcon stroke="#181E22" />,
  },
  {
    name: "Leaders",
    path: "/racing-arena/leaderboard",
    icon: <LeaderboardIcon stroke="#181E22" />,
  },
];

export const footerItems = [
  {
    category: "RACING",
    subnav: [
      {
        path: "/racing-arena/upcoming",
        title: "Upcoming",
      },
      {
        path: "/racing-arena/next-to-race",
        title: "Next To Race",
      },
      {
        path: "racing-arena/results",
        title: "Results",
      },
      {
        path: "/racing-arena/leaderboard",
        title: "Leaderboard",
      },
      {
        path: "/racing-arena/my-area",
        title: "My Area",
      },
    ],
  },
  {
    category: "FLEET",
    subnav: [
      {
        path: "/fleet",
        title: "My Fleet",
      },
      {
        path: "/garage",
        title: "Garage",
      },
      {
        path: "/junkyard",
        title: "Junkyard",
      },
    ],
  },
  {
    category: "MARKETPLACE",
    subnav: [
      { path: "/mint", title: "Mint" },
      { path: "/leasing", title: "Leasing" },
      {
        path: "https://opensea.io/collection/world-of-freight",
        title: "OpenSea",
      },
    ],
  },
  {
    category: "LEARN",
    subnav: [
      {
        title: "Learn more",
        path: "/about/utility",
      },
      { path: "/about", title: "About Us" },
      { path: "/freight-punks", title: "Freight Punks" },
      { path: "/wof-token", title: "$WOF Tokens" },
      {
        path: "https://worldoffreight.gitbook.io/world-of-freight/",
        title: "Wiki",
      },
      {
        path: "/how-to-mint",
        title: "How to Mint",
      },
      {
        path: "/nft-worlds",
        title: "NFT Worlds",
      },
      {
        title: "AMA Input Form",
        path: "/ama-form",
      },
    ],
  },
];

export const fleetLinks = [
  "/racing-arena",
  "/fleet",
  "/garage",
  "/junkyard",
  "/drivers",
  "/leasing",
];
