export default function formatNumber(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
    : num % 1 !== 0
    ? (Math.sign(num) * Math.abs(num)).toFixed(3)
    : Math.sign(num) * Math.abs(num);
}

export function formatBalance(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
    : (Math.sign(num) * Math.abs(num)).toFixed(3);
}
