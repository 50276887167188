export const UpcomingRaces = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.23"
      height="19.161"
      viewBox="0 0 20.23 19.161"
    >
      <g
        id="Group_3736"
        data-name="Group 3736"
        transform="translate(-1294.759 -1438.61)"
      >
        <g
          id="Group_3725"
          data-name="Group 3725"
          transform="translate(1294.759 1438.61)"
        >
          <path
            id="Path_957"
            data-name="Path 957"
            d="M1300.346,1457.771a10.114,10.114,0,1,1,9.059,0l-.463-.922a9.084,9.084,0,1,0-8.133,0Z"
            transform="translate(-1294.759 -1438.61)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3726"
          data-name="Group 3726"
          transform="translate(1304.359 1439.133)"
        >
          <rect
            id="Rectangle_699"
            data-name="Rectangle 699"
            width="1.031"
            height="3.073"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3727"
          data-name="Group 3727"
          transform="translate(1299.642 1440.157)"
        >
          <rect
            id="Rectangle_700"
            data-name="Rectangle 700"
            width="1.031"
            height="3.073"
            transform="translate(0 0.516) rotate(-29.999)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3728"
          data-name="Group 3728"
          transform="translate(1296.327 1443.472)"
        >
          <rect
            id="Rectangle_701"
            data-name="Rectangle 701"
            width="1.031"
            height="3.073"
            transform="translate(0 0.893) rotate(-60)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3729"
          data-name="Group 3729"
          transform="translate(1295.302 1448.189)"
        >
          <rect
            id="Rectangle_702"
            data-name="Rectangle 702"
            width="3.073"
            height="1.031"
            transform="translate(0 0)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3730"
          data-name="Group 3730"
          transform="translate(1296.327 1451.507)"
        >
          <rect
            id="Rectangle_703"
            data-name="Rectangle 703"
            width="3.073"
            height="1.031"
            transform="translate(0 1.537) rotate(-30)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3731"
          data-name="Group 3731"
          transform="translate(1310.244 1451.507)"
        >
          <rect
            id="Rectangle_704"
            data-name="Rectangle 704"
            width="1.031"
            height="3.073"
            transform="translate(0 0.893) rotate(-60)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3732"
          data-name="Group 3732"
          transform="translate(1311.373 1448.189)"
        >
          <rect
            id="Rectangle_705"
            data-name="Rectangle 705"
            width="3.073"
            height="1.031"
            transform="translate(0 0)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3733"
          data-name="Group 3733"
          transform="translate(1310.244 1443.472)"
        >
          <rect
            id="Rectangle_706"
            data-name="Rectangle 706"
            width="3.073"
            height="1.031"
            transform="translate(0 1.537) rotate(-30)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3734"
          data-name="Group 3734"
          transform="translate(1307.677 1440.157)"
        >
          <rect
            id="Rectangle_707"
            data-name="Rectangle 707"
            width="3.073"
            height="1.031"
            transform="matrix(0.5, -0.866, 0.866, 0.5, 0, 2.661)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3735"
          data-name="Group 3735"
          transform="translate(1300.68 1445.605)"
        >
          <rect
            id="Rectangle_708"
            data-name="Rectangle 708"
            width="1.031"
            height="7.736"
            transform="translate(0 0.729) rotate(-45)"
            fill={fill || "#fff"}
          />
        </g>
      </g>
    </svg>
  );
};

export const RaceResults = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.432"
      height="16.954"
      viewBox="0 0 28.432 16.954"
    >
      <g
        id="Group_3724"
        data-name="Group 3724"
        transform="translate(-580.589 -2598.562)"
      >
        <g
          id="Group_3718"
          data-name="Group 3718"
          transform="translate(589.651 2598.562)"
        >
          <path
            id="Path_954"
            data-name="Path 954"
            d="M606.139,2615.516H595.831v-16.954h10.308Zm-9.062-1.246h7.816v-14.462h-7.816Z"
            transform="translate(-595.831 -2598.562)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3719"
          data-name="Group 3719"
          transform="translate(580.589 2604.127)"
        >
          <path
            id="Path_955"
            data-name="Path 955"
            d="M590.9,2619.311H580.589v-11.389H590.9Zm-9.062-1.246h7.816v-8.9h-7.816Z"
            transform="translate(-580.589 -2607.922)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3720"
          data-name="Group 3720"
          transform="translate(598.713 2606.785)"
        >
          <path
            id="Path_956"
            data-name="Path 956"
            d="M621.381,2621.124H611.073v-8.731h10.308Zm-9.063-1.246h7.816v-6.239h-7.816Z"
            transform="translate(-611.073 -2612.393)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3721"
          data-name="Group 3721"
          transform="translate(593.565 2601.912)"
        >
          <rect
            id="Rectangle_696"
            data-name="Rectangle 696"
            width="2.48"
            height="1.246"
            transform="translate(0 0)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3722"
          data-name="Group 3722"
          transform="translate(602.627 2609.96)"
        >
          <rect
            id="Rectangle_697"
            data-name="Rectangle 697"
            width="2.48"
            height="1.246"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3723"
          data-name="Group 3723"
          transform="translate(584.503 2607.598)"
        >
          <rect
            id="Rectangle_698"
            data-name="Rectangle 698"
            width="2.48"
            height="1.246"
            transform="translate(0 0)"
            fill={fill || "#fff"}
          />
        </g>
      </g>
    </svg>
  );
};

export const MyRacingArea = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.221"
      height="16.251"
      viewBox="0 0 16.221 16.251"
    >
      <g
        id="Group_3745"
        data-name="Group 3745"
        transform="translate(-942.92 -3009.694)"
      >
        <g
          id="Group_3738"
          data-name="Group 3738"
          transform="translate(942.92 3009.694)"
        >
          <g id="Group_3737" data-name="Group 3737">
            <path
              id="Path_958"
              data-name="Path 958"
              d="M946.29,3016.433a3.37,3.37,0,1,1,3.369-3.37A3.373,3.373,0,0,1,946.29,3016.433Zm0-5.877a2.507,2.507,0,1,0,2.507,2.507A2.51,2.51,0,0,0,946.29,3010.556Z"
              transform="translate(-942.92 -3009.694)"
              fill={fill || "#fff"}
            />
          </g>
        </g>
        <g
          id="Group_3740"
          data-name="Group 3740"
          transform="translate(952.401 3009.694)"
        >
          <g id="Group_3739" data-name="Group 3739">
            <path
              id="Path_959"
              data-name="Path 959"
              d="M969.336,3016.433a3.37,3.37,0,1,1,3.369-3.37A3.373,3.373,0,0,1,969.336,3016.433Zm0-5.877a2.507,2.507,0,1,0,2.507,2.507A2.51,2.51,0,0,0,969.336,3010.556Z"
              transform="translate(-965.966 -3009.694)"
              fill={fill || "#fff"}
            />
          </g>
        </g>
        <g
          id="Group_3742"
          data-name="Group 3742"
          transform="translate(942.92 3019.205)"
        >
          <g id="Group_3741" data-name="Group 3741" transform="translate(0 0)">
            <path
              id="Path_960"
              data-name="Path 960"
              d="M946.29,3039.553a3.37,3.37,0,1,1,3.369-3.37A3.374,3.374,0,0,1,946.29,3039.553Zm0-5.878a2.508,2.508,0,1,0,2.507,2.508A2.51,2.51,0,0,0,946.29,3033.675Z"
              transform="translate(-942.92 -3032.813)"
              fill={fill || "#fff"}
            />
          </g>
        </g>
        <g
          id="Group_3744"
          data-name="Group 3744"
          transform="translate(952.401 3019.205)"
        >
          <g id="Group_3743" data-name="Group 3743" transform="translate(0 0)">
            <path
              id="Path_961"
              data-name="Path 961"
              d="M969.336,3039.553a3.37,3.37,0,1,1,3.369-3.37A3.374,3.374,0,0,1,969.336,3039.553Zm0-5.878a2.508,2.508,0,1,0,2.507,2.508A2.51,2.51,0,0,0,969.336,3033.675Z"
              transform="translate(-965.966 -3032.813)"
              fill={fill || "#fff"}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const LeaderboardIconSmall = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.823"
      height="17.431"
      viewBox="0 0 28.823 17.431"
    >
      <g
        id="Group_4275"
        data-name="Group 4275"
        transform="translate(-4284.138 -2488.106)"
      >
        <g
          id="Group_3578"
          data-name="Group 3578"
          transform="translate(4284.138 2488.106)"
        >
          <g
            id="Group_3572"
            data-name="Group 3572"
            transform="translate(1.73 2.833)"
          >
            <path
              id="Path_908"
              data-name="Path 908"
              d="M4299.3,2506.008a11,11,0,0,1-10.985-10.986h1.142a9.855,9.855,0,0,0,9.844,9.844Z"
              transform="translate(-4288.315 -2495.022)"
              fill={fill || "#fff"}
            />
          </g>
          <g
            id="Group_3573"
            data-name="Group 3573"
            transform="translate(0.499)"
          >
            <path
              id="Path_909"
              data-name="Path 909"
              d="M4287.856,2492.741l-.293-.177c-.155-.093-1.509-.94-1.509-2.141s1.354-2.049,1.509-2.141l.293-.176.294.176c.155.093,1.509.941,1.509,2.141s-1.354,2.049-1.509,2.141Zm0-3.27c-.309.25-.661.627-.661.952s.352.7.661.952c.309-.25.662-.626.662-.952S4288.166,2489.722,4287.856,2489.471Z"
              transform="translate(-4286.054 -2488.106)"
              fill={fill || "#fff"}
            />
          </g>
          <g
            id="Group_3574"
            data-name="Group 3574"
            transform="translate(0 4.417)"
          >
            <path
              id="Path_910"
              data-name="Path 910"
              d="M4287.7,2499.383a2.568,2.568,0,0,1-2.565-2.566h1.141a1.424,1.424,0,0,0,2.849,0h1.141A2.568,2.568,0,0,1,4287.7,2499.383Z"
              transform="translate(-4285.138 -2496.817)"
              fill={fill || "#fff"}
            />
          </g>
          <g
            id="Group_3575"
            data-name="Group 3575"
            transform="translate(1.151 6.616)"
          >
            <path
              id="Path_911"
              data-name="Path 911"
              d="M4289.585,2504.878a2.568,2.568,0,0,1-2.333-1.49l1.036-.478a1.424,1.424,0,0,0,2.586-1.194l1.036-.479a2.564,2.564,0,0,1-2.325,3.641Z"
              transform="translate(-4287.252 -2501.237)"
              fill={fill || "#fff"}
            />
          </g>
          <g
            id="Group_3576"
            data-name="Group 3576"
            transform="translate(3.926 8.567)"
          >
            <path
              id="Path_912"
              data-name="Path 912"
              d="M4294.075,2510.006a2.544,2.544,0,0,1-1.729-.673l.77-.842a1.424,1.424,0,1,0,1.922-2.1l.771-.842a2.565,2.565,0,0,1-1.617,4.456Z"
              transform="translate(-4292.346 -2505.548)"
              fill={fill || "#fff"}
            />
          </g>
          <g
            id="Group_3577"
            data-name="Group 3577"
            transform="translate(8.509 10.154)"
          >
            <path
              id="Path_913"
              data-name="Path 913"
              d="M4301.566,2513.465a2.574,2.574,0,0,1-.808-.13l.358-1.085a1.424,1.424,0,1,0,.893-2.7l.358-1.084a2.567,2.567,0,0,1-.8,5Z"
              transform="translate(-4300.759 -2508.462)"
              fill={fill || "#fff"}
            />
          </g>
        </g>
        <g
          id="Group_3585"
          data-name="Group 3585"
          transform="translate(4300.245 2488.106)"
        >
          <g
            id="Group_3579"
            data-name="Group 3579"
            transform="translate(0 2.833)"
          >
            <path
              id="Path_914"
              data-name="Path 914"
              d="M4314.133,2506.008v-1.142a9.855,9.855,0,0,0,9.844-9.844h1.141A11,11,0,0,1,4314.133,2506.008Z"
              transform="translate(-4314.133 -2495.022)"
              fill={fill || "#fff"}
            />
          </g>
          <g
            id="Group_3580"
            data-name="Group 3580"
            transform="translate(8.612)"
          >
            <path
              id="Path_915"
              data-name="Path 915"
              d="M4331.746,2492.741l-.294-.177c-.154-.093-1.509-.94-1.509-2.141s1.355-2.049,1.509-2.141l.294-.176.294.176c.154.093,1.509.941,1.509,2.141s-1.354,2.049-1.509,2.141Zm0-3.27c-.309.25-.661.627-.661.952s.355.7.661.952c.309-.25.661-.626.661-.952S4332.055,2489.722,4331.746,2489.471Z"
              transform="translate(-4329.943 -2488.106)"
              fill={fill || "#fff"}
            />
          </g>
          <g
            id="Group_3581"
            data-name="Group 3581"
            transform="translate(7.585 4.417)"
          >
            <path
              id="Path_916"
              data-name="Path 916"
              d="M4330.623,2499.383a2.568,2.568,0,0,1-2.565-2.566h1.142a1.424,1.424,0,0,0,2.848,0h1.142A2.569,2.569,0,0,1,4330.623,2499.383Z"
              transform="translate(-4328.057 -2496.817)"
              fill={fill || "#fff"}
            />
          </g>
          <g
            id="Group_3582"
            data-name="Group 3582"
            transform="translate(6.67 6.616)"
          >
            <path
              id="Path_917"
              data-name="Path 917"
              d="M4328.939,2504.878a2.564,2.564,0,0,1-2.325-3.641l1.037.479a1.424,1.424,0,0,0,2.585,1.194l1.037.478A2.57,2.57,0,0,1,4328.939,2504.878Z"
              transform="translate(-4326.377 -2501.237)"
              fill={fill || "#fff"}
            />
          </g>
          <g
            id="Group_3583"
            data-name="Group 3583"
            transform="translate(4.492 8.567)"
          >
            <path
              id="Path_918"
              data-name="Path 918"
              d="M4324.949,2510.006l-.118,0a2.565,2.565,0,0,1-1.617-4.456l.77.842a1.424,1.424,0,1,0,1.923,2.1l.77.842A2.543,2.543,0,0,1,4324.949,2510.006Z"
              transform="translate(-4322.38 -2505.548)"
              fill={fill || "#fff"}
            />
          </g>
          <g
            id="Group_3584"
            data-name="Group 3584"
            transform="translate(0.836 10.154)"
          >
            <path
              id="Path_919"
              data-name="Path 919"
              d="M4318.231,2513.465a2.566,2.566,0,0,1-.8-5l.358,1.084a1.424,1.424,0,1,0,.893,2.7l.357,1.085A2.561,2.561,0,0,1,4318.231,2513.465Z"
              transform="translate(-4315.667 -2508.462)"
              fill={fill || "#fff"}
            />
          </g>
        </g>
        <g
          id="Group_3586"
          data-name="Group 3586"
          transform="translate(4294.48 2488.228)"
        >
          <path
            id="Path_920"
            data-name="Path 920"
            d="M4308.53,2505.068h-1.142V2490.94l-2.938,2.783-.807-.764,4.887-4.629Z"
            transform="translate(-4303.643 -2488.329)"
            fill={fill || "#fff"}
          />
        </g>
        <g
          id="Group_3587"
          data-name="Group 3587"
          transform="translate(4295.364 2504.395)"
        >
          <rect
            id="Rectangle_644"
            data-name="Rectangle 644"
            width="6.635"
            height="1.142"
            fill={fill || "#fff"}
          />
        </g>
      </g>
    </svg>
  );
};

export const Search = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.777"
      height="19.777"
      viewBox="0 0 19.777 19.777"
    >
      <path
        id="Icon_material-search"
        data-name="Icon material-search"
        d="M18.634,16.938h-.893l-.317-.305a7.361,7.361,0,1,0-.792.792l.305.317v.893l5.654,5.642,1.685-1.685Zm-6.784,0a5.088,5.088,0,1,1,5.088-5.088A5.082,5.082,0,0,1,11.85,16.938Z"
        transform="translate(-4.5 -4.5)"
        fill="#fff"
      />
    </svg>
  );
};

export const UpcomingIcon = ({ stroke }) => {
  return (
    <svg
      id="Group_4756"
      data-name="Group 4756"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_4755" data-name="Group 4755">
        <circle
          id="Ellipse_475"
          data-name="Ellipse 475"
          cx="11.5"
          cy="11.5"
          r="11.5"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1428"
          data-name="Path 1428"
          d="M3,12a9,9,0,0,1,18,0"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_531"
          data-name="Line 531"
          y2="5"
          transform="translate(12 7.5)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          id="Ellipse_476"
          data-name="Ellipse 476"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(10.5 12.5)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export const NextToRaceIcon = ({ stroke }) => {
  return (
    <svg
      id="Group_4761"
      data-name="Group 4761"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_4760" data-name="Group 4760">
        <circle
          id="Ellipse_478"
          data-name="Ellipse 478"
          cx="11.5"
          cy="11.5"
          r="11.5"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1431"
          data-name="Path 1431"
          d="M18.973,15.42c-.179,1.889-1.033,3.271-2.9,2.783C8.4,16.2,5.048,16.816,4.99,11.754a6.652,6.652,0,0,1,6.784-6.818c3.3,0,4.868,1.681,6.037,4.452.2.466.382.963.563,1.488A11.47,11.47,0,0,1,18.973,15.42Z"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1432"
          data-name="Path 1432"
          d="M18.973,15.42l-.111-.023-6.3-1.492A2.8,2.8,0,0,1,13.2,8.374a2.836,2.836,0,0,1,.648.076l3.963.938c.2.466.382.963.563,1.488A11.47,11.47,0,0,1,18.973,15.42Z"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export const ResultsIcon = ({ stroke }) => {
  return (
    <svg
      id="Group_4765"
      data-name="Group 4765"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_4764" data-name="Group 4764">
        <circle
          id="Ellipse_480"
          data-name="Ellipse 480"
          cx="11.5"
          cy="11.5"
          r="11.5"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1437"
          data-name="Path 1437"
          d="M20.469,14.132V12.621a.338.338,0,0,0-.338-.338H16.1a.337.337,0,0,1-.338-.337V8.615a.339.339,0,0,0-.338-.339h-6.5a.339.339,0,0,0-.338.339v1.84a.338.338,0,0,1-.338.338H4.26a.338.338,0,0,0-.338.337V14.2a.454.454,0,0,0,.455.453H19.952A.517.517,0,0,0,20.469,14.132Z"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          id="Line_538"
          data-name="Line 538"
          y2="2.348"
          transform="translate(11.804 10.289)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export const LeaderboardIcon = ({ stroke }) => {
  return (
    <svg
      id="Group_4763"
      data-name="Group 4763"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_4762" data-name="Group 4762">
        <circle
          id="Ellipse_479"
          data-name="Ellipse 479"
          cx="11.5"
          cy="11.5"
          r="11.5"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1433"
          data-name="Path 1433"
          d="M12,15.4h0a4.239,4.239,0,0,1-4.239-4.239V6.36h8.478v4.8A4.239,4.239,0,0,1,12,15.4Z"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1434"
          data-name="Path 1434"
          d="M12,15.4c-.283,1.13.131,3.226-2.447,3.957h4.894C11.869,18.629,12.283,16.533,12,15.4Z"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1435"
          data-name="Path 1435"
          d="M16.239,11.446h0V6.924H18.5V9.185A2.261,2.261,0,0,1,16.239,11.446Z"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1436"
          data-name="Path 1436"
          d="M7.761,11.446h0V6.924H5.5V9.185A2.261,2.261,0,0,0,7.761,11.446Z"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export const MyAreaIcon = ({ stroke }) => {
  return (
    <svg
      id="Group_4767"
      data-name="Group 4767"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_4766" data-name="Group 4766">
        <path
          id="Path_1438"
          data-name="Path 1438"
          d="M15.5,9.321a3.5,3.5,0,0,1-4.152,3.441A3.8,3.8,0,0,1,8.5,9.15V8.092A3.8,3.8,0,0,1,11.348,4.48,3.5,3.5,0,0,1,15.5,7.921Z"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          id="Ellipse_481"
          data-name="Ellipse 481"
          cx="11.5"
          cy="11.5"
          r="11.5"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1439"
          data-name="Path 1439"
          d="M4.16,20.41A8.522,8.522,0,0,1,12,15.5a8.522,8.522,0,0,1,7.84,4.91"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export const SliderCaret = ({ dir }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.64"
      height="15.622"
      viewBox="0 0 10.64 15.622"
    >
      <path
        d="M0,0,6.4,6.4,0,12.793"
        transform={
          dir === "right"
            ? "translate(1.415 1.414)"
            : "translate(9.225 14.208) rotate(180)"
        }
        fill="none"
        stroke="#f1b361"
        strokeWidth="4"
      />
    </svg>
  );
};

export const FilterGears = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.177"
      height="21.657"
      viewBox="0 0 24.177 21.657"
    >
      <path
        id="outline-filters-1"
        d="M23.157,16.6a2.52,2.52,0,0,0,0-5.039m0,5.039a2.52,2.52,0,0,1,0-5.039m0,5.039v7.559m0-12.6V4M14.338,19.118a2.52,2.52,0,1,0,2.52,2.52A2.52,2.52,0,0,0,14.338,19.118Zm0,0V4M5.52,9.039A2.52,2.52,0,1,0,3,6.52,2.52,2.52,0,0,0,5.52,9.039Zm0,0V24.157"
        transform="translate(-2.25 -3.25)"
        fill="none"
        stroke="#a8adb9"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.418"
      height="17.02"
      viewBox="0 0 15.418 17.02"
    >
      <g
        id="Icon_feather-calendar"
        data-name="Icon feather-calendar"
        transform="translate(-4 -2.5)"
      >
        <path
          id="Path_1411"
          data-name="Path 1411"
          d="M6.1,6H17.316a1.6,1.6,0,0,1,1.6,1.6V18.816a1.6,1.6,0,0,1-1.6,1.6H6.1a1.6,1.6,0,0,1-1.6-1.6V7.6A1.6,1.6,0,0,1,6.1,6Z"
          transform="translate(0 -1.398)"
          fill="none"
          stroke="#5c616c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1412"
          data-name="Path 1412"
          d="M24,3V6.2"
          transform="translate(-9.087)"
          fill="none"
          stroke="#5c616c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1413"
          data-name="Path 1413"
          d="M12,3V6.2"
          transform="translate(-3.495)"
          fill="none"
          stroke="#5c616c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1414"
          data-name="Path 1414"
          d="M4.5,15H18.918"
          transform="translate(0 -5.592)"
          fill="none"
          stroke="#5c616c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export const SortIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.227"
      height="17.698"
      viewBox="0 0 20.227 17.698"
    >
      <path
        id="Icon_awesome-sort-amount-down-alt"
        data-name="Icon awesome-sort-amount-down-alt"
        d="M9.481,4.778H12.01a.632.632,0,0,0,.632-.632V2.882a.632.632,0,0,0-.632-.632H9.481a.632.632,0,0,0-.632.632V4.146A.632.632,0,0,0,9.481,4.778Zm0,5.057h5.057A.632.632,0,0,0,15.17,9.2V7.939a.632.632,0,0,0-.632-.632H9.481a.632.632,0,0,0-.632.632V9.2A.632.632,0,0,0,9.481,9.835ZM19.594,17.42H9.481a.632.632,0,0,0-.632.632v1.264a.632.632,0,0,0,.632.632H19.594a.632.632,0,0,0,.632-.632V18.052A.632.632,0,0,0,19.594,17.42ZM9.481,14.892h7.585a.632.632,0,0,0,.632-.632V13a.632.632,0,0,0-.632-.632H9.481A.632.632,0,0,0,8.849,13V14.26A.632.632,0,0,0,9.481,14.892Zm-2.528,0h-1.9V2.882a.632.632,0,0,0-.632-.632H3.16a.632.632,0,0,0-.632.632v12.01H.632A.632.632,0,0,0,.186,15.97l3.16,3.792a.632.632,0,0,0,.894,0L7.4,15.97A.633.633,0,0,0,6.953,14.892Z"
        transform="translate(0 -2.25)"
        fill={fill || "#a8adb9"}
      />
    </svg>
  );
};

export const DoubleChevron = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.854"
      height="8.134"
      viewBox="0 0 6.854 8.134"
    >
      <g
        id="Group_4879"
        data-name="Group 4879"
        transform="translate(-230.863 -302.772)"
      >
        <path
          id="Path_1472"
          data-name="Path 1472"
          d="M20929.9-11877.034l3.074,3.073,3.072-3.073"
          transform="translate(-20698.68 12184.16)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
        />
        <path
          id="Path_1473"
          data-name="Path 1473"
          d="M20929.9-11877.034l3.074,3.073,3.072-3.073"
          transform="translate(-20698.68 12180.16)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export const Checkmark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.47"
      height="7.663"
      viewBox="0 0 10.47 7.663"
    >
      <path
        id="Path_1475"
        data-name="Path 1475"
        d="M10730.836-7616.152l3.514,3.514,5.542-5.542"
        transform="translate(-10730.129 7618.888)"
        fill="none"
        stroke="#000"
        strokeWidth="2"
      />
    </svg>
  );
};

export const Clock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.756"
      height="13.756"
      viewBox="0 0 13.756 13.756"
    >
      <g
        id="Icon_ionic-md-time"
        data-name="Icon ionic-md-time"
        transform="translate(-3.375 -3.375)"
      >
        <path
          id="Path_4143"
          data-name="Path 4143"
          d="M10.246,3.375a6.878,6.878,0,1,0,6.885,6.878A6.875,6.875,0,0,0,10.246,3.375Zm.007,12.38a5.5,5.5,0,1,1,5.5-5.5A5.5,5.5,0,0,1,10.253,15.755Z"
          fill="#7f7f7f"
        />
        <path
          id="Path_4144"
          data-name="Path 4144"
          d="M17.569,10.688H16.538v4.127l3.611,2.166.516-.847-3.1-1.835Z"
          transform="translate(-6.972 -3.874)"
          fill="#7f7f7f"
        />
      </g>
    </svg>
  );
};

export const Rainy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#DDDDDD"
    >
      <path d="M12,17.5a.5.5,0,0,1,.5.5v4a.5.5,0,0,1-1,0V18A.5.5,0,0,1,12,17.5ZM7.5,18v4a.5.5,0,0,0,1,0V18a.5.5,0,1,0-1,0Zm-4,0v4a.5.5,0,0,0,1,0V18a.5.5,0,0,0-1,0Zm12,0v4a.5.5,0,1,0,1,0V18a.5.5,0,0,0-1,0Zm4,0v4a.5.5,0,0,0,1,0V18a.5.5,0,0,0-1,0Zm.5-3.5H6.172a4.63,4.63,0,0,1-4.651-4.06A4.465,4.465,0,0,1,2.669,6.974,4.52,4.52,0,0,1,5.525,5.525,4.5,4.5,0,0,1,14.473,5.5H16A3.5,3.5,0,0,1,19.5,9v.107a.392.392,0,0,0,.392.393,2.567,2.567,0,0,1,2.489,1.723A2.507,2.507,0,0,1,20,14.5Zm1.424-2.984A1.561,1.561,0,0,0,19.893,10.5,1.394,1.394,0,0,1,18.5,9.107V9A2.5,2.5,0,0,0,16,6.5H14a.5.5,0,0,1-.5-.5,3.5,3.5,0,0,0-7,0,.5.5,0,0,1-.5.5,3.5,3.5,0,0,0-3.484,3.846A3.622,3.622,0,0,0,6.172,13.5H20a1.49,1.49,0,0,0,1.207-.609A1.53,1.53,0,0,0,21.425,11.516Z" />
    </svg>
  );
};

export const Sunny = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#DDDDDD"
    >
      <path d="M6.5,12A5.5,5.5,0,1,0,12,6.5,5.506,5.506,0,0,0,6.5,12ZM12,7.5A4.5,4.5,0,1,1,7.5,12,4.505,4.505,0,0,1,12,7.5Zm0-3a.5.5,0,0,1-.5-.5V2a.5.5,0,0,1,1,0V4A.5.5,0,0,1,12,4.5Zm0,15a.5.5,0,0,1,.5.5v2a.5.5,0,0,1-1,0V20A.5.5,0,0,1,12,19.5ZM19.425,5.283,18.011,6.7A.5.5,0,1,1,17.3,5.99l1.414-1.414a.5.5,0,0,1,.707.707ZM6.7,17.3a.5.5,0,0,1,0,.707L5.282,19.425a.5.5,0,1,1-.707-.707L5.99,17.3A.5.5,0,0,1,6.7,17.3ZM22.5,12a.5.5,0,0,1-.5.5H20a.5.5,0,0,1,0-1h2A.5.5,0,0,1,22.5,12Zm-18,0a.5.5,0,0,1-.5.5H2a.5.5,0,0,1,0-1H4A.5.5,0,0,1,4.5,12Zm13.511,5.3,1.414,1.415a.5.5,0,0,1-.707.707L17.3,18.01a.5.5,0,0,1,.707-.707ZM6.7,5.99A.5.5,0,0,1,5.99,6.7L4.575,5.283a.5.5,0,1,1,.707-.707Z" />
    </svg>
  );
};

export const Stormy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#DDDDDD"
    >
      <path
        d="M17.7,14.2H6.3C4,14.2,1.9,12,1.9,9.6C1.9,7.2,4,5,6.3,5C6.9,5,7.5,5.1,8,5.4c0.1,0.1,0.3,0.1,0.4,0C8.6,5.3,8.7,5.1,8.7,5
	C9,2.8,10.8,1.2,13,1.2c2.1,0,3.8,1.4,4.2,3.4C17.3,4.8,17.4,5,17.7,5c0.1,0,0.1,0,0.1,0c2.3,0,4.3,2.1,4.3,4.6
	C22,12,20,14.2,17.7,14.2z M18,4c-0.6-2.2-2.7-3.8-5.1-3.8c-2.5,0-4.6,1.7-5.1,4.1C7.3,4.1,6.8,4,6.3,4C3.4,4,1,6.6,1,9.6
	s2.4,5.6,5.3,5.6h11.5c2.8,0,5.3-2.5,5.3-5.6C23,6.7,20.8,4.2,18,4z"
      />
      <path
        d="M7.7,23.9C7.8,23.9,7.9,24,8,24c0.1,0,0.2-0.1,0.4-0.1l3-3.1c0.1-0.1,0.2-0.4,0.1-0.5c-0.1-0.2-0.2-0.3-0.4-0.3H9.9l1.9-1.8
	c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0L8.4,20c-0.1,0.1-0.2,0.4-0.1,0.5c0.1,0.2,0.2,0.3,0.4,0.3h1.2l-2.2,2.3
	C7.5,23.4,7.5,23.7,7.7,23.9z"
      />
      <path
        d="M14.5,20.2l2.4-2.4c0.2-0.2,0.2-0.5,0-0.7s-0.5-0.2-0.7,0l-2.4,2.4c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.3,0.1
	C14.3,20.3,14.4,20.3,14.5,20.2z"
      />
      <path
        d="M4.9,20.2l2.4-2.4c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0l-2.4,2.4C4,19.7,4,20,4.2,20.2c0.1,0.1,0.2,0.1,0.3,0.1
	C4.7,20.3,4.8,20.3,4.9,20.2z"
      />
    </svg>
  );
};

export const Windy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#DDDDDD"
    >
      <path d="M1.5,8A.5.5,0,0,1,2,7.5H9a2.5,2.5,0,0,0,0-5H8a.5.5,0,0,1,0-1H9a3.5,3.5,0,0,1,0,7H2A.5.5,0,0,1,1.5,8ZM2,16.5H19a2.5,2.5,0,0,1,0,5H18a.5.5,0,0,0,0,1h1a3.5,3.5,0,0,0,0-7H2a.5.5,0,0,0,0,1Zm0-4H19a3.5,3.5,0,0,0,0-7H18a.5.5,0,0,0,0,1h1a2.5,2.5,0,0,1,0,5H2a.5.5,0,0,0,0,1Z" />
    </svg>
  );
};

export const Foggy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#DDDDDD"
    >
      <path d="M22.332,16.668a.5.5,0,0,1-.664.747c-5.009-4.459-6.912-3.111-10.062-.88-.422.3-.861.611-1.328.922a5.65,5.65,0,0,1-3.192,1,8.772,8.772,0,0,1-5.439-2.392.5.5,0,0,1,.707-.707c.151.15,3.758,3.682,7.369,1.271.458-.306.891-.611,1.3-.9C14.214,13.464,16.731,11.681,22.332,16.668ZM11.027,20.721c-.378.267-.771.546-1.184.824a.5.5,0,1,0,.559.83c.421-.284.819-.566,1.2-.838,3.153-2.233,5.054-3.58,10.063.877a.5.5,0,0,0,.664-.746C16.731,16.681,14.212,18.465,11.027,20.721ZM16,5.459H14.473a4.5,4.5,0,0,0-8.948.025A4.518,4.518,0,0,0,2.669,6.933,4.464,4.464,0,0,0,1.521,10.4a4.629,4.629,0,0,0,4.651,4.06h4.041c.509-.359,1.007-.7,1.5-1H6.172A3.621,3.621,0,0,1,2.517,10.3,3.5,3.5,0,0,1,6,6.459a.5.5,0,0,0,.5-.5,3.5,3.5,0,0,1,7,0,.5.5,0,0,0,.5.5h2a2.5,2.5,0,0,1,2.5,2.5v.107a1.4,1.4,0,0,0,1.393,1.393,1.562,1.562,0,0,1,1.532,1.016,1.527,1.527,0,0,1-.218,1.375,1.483,1.483,0,0,1-.748.529c.307.188.622.4.946.627a2.471,2.471,0,0,0,.606-.562,2.52,2.52,0,0,0,.371-2.262,2.567,2.567,0,0,0-2.489-1.723.393.393,0,0,1-.393-.393V8.959A3.5,3.5,0,0,0,16,5.459Z" />
    </svg>
  );
};

export const Snowy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#DDDDDD"
    >
      <path d="M17.5,16a.5.5,0,0,1-.5.5H13.207l2.682,2.682a.5.5,0,0,1-.707.707L12.5,17.207V21a.5.5,0,0,1-1,0V17.207L8.818,19.889a.5.5,0,0,1-.707-.707L10.793,16.5H7a.5.5,0,0,1,0-1h3.793L8.111,12.818a.5.5,0,0,1,.707-.707L11.5,14.793V11a.5.5,0,0,1,1,0v3.793l2.682-2.682a.5.5,0,0,1,.707.707L13.207,15.5H17A.5.5,0,0,1,17.5,16Zm2.393-5.5a.393.393,0,0,1-.393-.393V10A3.5,3.5,0,0,0,16,6.5H14.473a4.5,4.5,0,0,0-8.948.025A4.513,4.513,0,0,0,2.67,7.974,4.465,4.465,0,0,0,1.521,11.44a4.568,4.568,0,0,0,3.591,3.927,1.994,1.994,0,0,1,.626-.907,3.588,3.588,0,0,1-3.222-3.115A3.5,3.5,0,0,1,6,7.5.5.5,0,0,0,6.5,7a3.5,3.5,0,0,1,7,0,.5.5,0,0,0,.5.5h2A2.5,2.5,0,0,1,18.5,10v.107A1.4,1.4,0,0,0,19.893,11.5a1.562,1.562,0,0,1,1.532,1.016,1.53,1.53,0,0,1-.218,1.375A1.487,1.487,0,0,1,20,14.5H18.311a2,2,0,0,1,.619,1H20a2.507,2.507,0,0,0,2.382-3.277A2.567,2.567,0,0,0,19.893,10.5Z" />
    </svg>
  );
};

export const Icy = () => {
  return (
    <svg
      id="ice"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M5.381,4.876A4.631,4.631,0,0,1,7.2,5.269a.507.507,0,0,0,.448-.028A.55.55,0,0,0,7.9,4.876a4.4,4.4,0,0,1,8.688-.392.513.513,0,0,0,.5.392h.084c2.494,0,4.428,2.69,4.428,5.016s-1.934,5.016-4.428,5.016H5.409c-2.494,0-4.428-2.69-4.428-5.016S2.887,4.876,5.381,4.876Zm0,11.042h11.77c3.083,0,5.409-3.167,5.409-6,0-2.746-2.158-5.8-5.1-6a5.382,5.382,0,0,0-10.425.252,5.458,5.458,0,0,0-1.625-.252C2.326,3.923,0,7.09,0,9.921S2.27,15.918,5.381,15.918Z"
        fill="#ddd"
      />
      <path
        d="M14.763,64.44l1.878-1.878a.377.377,0,0,0-.532-.532L14.23,63.908a.39.39,0,0,0,0,.532.417.417,0,0,0,.252.112A1.932,1.932,0,0,0,14.763,64.44Z"
        transform="translate(-10.167 -44.571)"
        fill="#ddd"
      />
      <path
        d="M22.363,76.619l.757-.757a.377.377,0,0,0-.532-.532l-.757.757a.39.39,0,0,0,0,.532.417.417,0,0,0,.252.112A.266.266,0,0,0,22.363,76.619Z"
        transform="translate(-15.637 -54.143)"
        fill="#ddd"
      />
      <path
        d="M28.539,59.417h0a.819.819,0,0,1,.454.15l.035.023,1.144,1.144.018.024a.846.846,0,0,1,.16.7l0,.013-.393,1.488a.748.748,0,0,1-.556.562l-1.489.393a.784.784,0,0,1-.19.023.773.773,0,0,1-.546-.218L26.08,62.624a.787.787,0,0,1-.195-.735v-.005l.39-1.476a.748.748,0,0,1,.556-.562L28.3,59.46A.726.726,0,0,1,28.539,59.417Zm.987,1.861L28.518,60.27l-1.436.379-.374,1.414,1.025,1.025,1.414-.374.378-1.432Z"
        transform="translate(-18.614 -42.766)"
        fill="#ddd"
      />
      <path
        d="M46.127,63.462a.773.773,0,0,1,.546.218L48.1,65.109a.7.7,0,0,1,.184.775L47.765,67.8a.748.748,0,0,1-.554.56l-1.966.534a.784.784,0,0,1-.19.023.773.773,0,0,1-.546-.218L43.08,67.273a.787.787,0,0,1-.195-.735l0-.008.53-1.951a.748.748,0,0,1,.554-.56l1.967-.534A.784.784,0,0,1,46.127,63.462Zm1.346,2.207-1.361-1.361-1.891.513-.513,1.891,1.361,1.361,1.891-.513Z"
        transform="translate(-30.85 -45.677)"
        fill="#ddd"
      />
    </svg>
  );
};
