import { useAtom } from "jotai";
import { menuAtom } from "src/store";
import IconButton from "../buttons/IconButton";
import { Burger } from "../icons";

export default function Hamburger({ ...rest }) {
  const [open, setOpen] = useAtom(menuAtom);

  return (
    <IconButton
      onClick={() => setOpen(!open)}
      position={{ _: "absolute", desktop: "fixed" }}
      top={{ _: 50, desktop: 120 }}
      left={{ _: 20, desktop: 120 }}
      transform={{ lg_desktop: !open && "translateY(-50%) rotate(-90deg)" }}
      transition="all 0.3 ease"
      opacity={!open ? 1 : 0}
      zIndex={222}
      {...rest}
    >
      <Burger />
    </IconButton>
  );
}
