import { useAtom } from "jotai";
import { walletModalAtom } from "src/store/modals";
import useWallet from "hooks/useWallet";
import { Box } from "@xstyled/styled-components";
import Modal from "../Modal";
import DismissButton from "../buttons/DismissButton";
import { Text } from "../Text";
import { FlexBox } from "../Containers";
import Button from "../Button";

export default function WalletModal() {
  const [showWalletModal, setShowWalletModal] = useAtom(walletModalAtom);

  const handleConnectWallet = useWallet();

  return (
    <Modal
      isShown={showWalletModal}
      hide={() => setShowWalletModal(false)}
      width={{ _: "93%", sm_tablet: 450, desktop: 500 }}
      p={42}
      bg="#fff"
    >
      <FlexBox dir="column" mt={24}>
        <Text
          m={0}
          font="Semibold"
          size="1.25rem"
          color="#000"
          whiteSpace="nowrap"
          align="center"
          lineHeight={2}
        >
          Select connection method
        </Text>
        <Text m={0} size="0.875rem" color="#000">
          Use WalletConnect on mobile
        </Text>
        <FlexBox spaceX={10}>
          <Button onClick={() => handleConnectWallet("Metamask")} minW={150}>
            <Box
              as="img"
              src="/assets/wallet/metamask-fox.svg"
              alt="MetaMask"
              w={150}
              h={150}
            />
          </Button>
          <Button
            onClick={() => handleConnectWallet("WalletConnect")}
            minW={150}
          >
            <Box
              as="img"
              src="/assets/wallet/walletConnect.svg"
              alt="WalletConnect"
              w={150}
              h={150}
            />
          </Button>
        </FlexBox>
      </FlexBox>
      <DismissButton
        onClick={() => setShowWalletModal(false)}
        top={20}
        right={20}
        stroke="#000"
        transform="scale(0.5)"
      />
    </Modal>
  );
}
