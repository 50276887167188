import Link from "next/link";
import { useAtomValue, useSetAtom } from "jotai";
import { announcementPopoverAtom, userWalletAtom } from "src/store";
import { menu } from "src/data/menus";
import { getRacingMenuItems } from "src/utils/getMenuItems";
import styled, { Box, css } from "@xstyled/styled-components";
import { Chevron } from "@/UI/icons";
import Icon from "../Icon";

const List = styled.ulBox`
  display: flex;
  list-style: none;
  white-space: nowrap;
  height: 40px;
  margin-top: 2.15rem;

  li {
    &:nth-last-child(2) {
      margin-right: -1rem;
    }

    &:hover {
      color: #feb806;

      #Chevron {
        top: 0;
        right: 12;
        transform: translateY(15%) rotate(180deg) scale(1.15);
        transition: all ease-in-out 0.3s;

        svg path {
          fill: #feb806;
        }
      }
    }

    #Chevron {
      transition: all ease-in-out 0.3s;
    }
  }

  li:hover {
    ul {
      display: block;

      li {
        display: block;
      }
    }

    a.main_topic {
      color: #feb806;
    }
  }
`;

const ListItem = styled.liBox`
  position: relative;
  padding-right: 32px;
  color: #fff;
  cursor: pointer;

  ${({ $title }) => {
    if ($title === "Punks" || $title === "Bridge") {
      return css`
        padding-right: 16px;
      `;
    }

    /* if ($title === "Marketplace" || $title === "Leasing") {
      return css`
        color: #feb806;

        #Chevron {
          svg path {
            fill: #feb806;
          }
        }
      `;
    } */
  }}
`;

export default function Menu() {
  const { address } = useAtomValue(userWalletAtom);
  const setOpen = useSetAtom(announcementPopoverAtom);

  return (
    <List spaceX={{ desktop: 10, lg_desktop: 20 }}>
      {menu.map(({ title, path, external, subnav, button }) => {
        const subnavItems = getRacingMenuItems({ address, items: subnav });

        return (
          <ListItem key={title} $title={title}>
            {!button ? (
              <Link href={path}>
                <a
                  className="main_topic"
                  target={external ? "_blank" : "_self"}
                >
                  {title}
                  {subnav && (
                    <Icon
                      id="Chevron"
                      aria="Show dropdown menu"
                      position="absolute"
                      right={14}
                      top={-1}
                      transform="scale(1.15)"
                    >
                      <Chevron />
                    </Icon>
                  )}
                </a>
              </Link>
            ) : (
              <Box
                as="button"
                onClick={() => setOpen(true)}
                background="none"
                border="none"
                color="#fff"
                hoverColor="#feb806"
                fontFamily="Lufga-Regular"
                fontSize={16}
                cursor="pointer"
              >
                {title}
              </Box>
            )}

            {subnav && (
              <Box
                as="ul"
                display="none"
                position="absolute"
                p="16px 32px 16px 16px"
                mt={8}
                bg="#161B27"
                borderRadius={7}
              >
                {subnavItems?.map(({ title, path, external }) => {
                  return (
                    <ListItem key={title} $title={title} lineHeight={2}>
                      <Link href={path}>
                        <a
                          target={external ? "_blank" : "_self"}
                          onClick={() =>
                            title === "Learn more" &&
                            window.fbq("trackCustom", "learn")
                          }
                        >
                          {title}
                        </a>
                      </Link>
                    </ListItem>
                  );
                })}
              </Box>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}
