import { Box } from "@xstyled/styled-components";

export default function IconButton({
  children,
  onClick,
  aria,
  disabled,
  ...rest
}) {
  return (
    <Box
      as="button"
      type="button"
      p={0}
      border="none"
      bg="transparent"
      cursor={!disabled && "pointer"}
      disabled={disabled}
      onClick={onClick}
      fontFamily="Lufga-Regular"
      {...rest}
    >
      {children}
    </Box>
  );
}
