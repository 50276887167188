import { Box } from "@xstyled/styled-components";

export const Text = ({ children, max, font, size, align, ...rest }) => {
  return (
    <Box
      as="p"
      maxW={max}
      fontFamily={`Lufga-${font || "Regular"}`}
      fontSize={size || { _: "1.25rem", desktop: "1.625rem" }}
      textAlign={align}
      lineHeight={1.5}
      {...rest}
    >
      {children}
    </Box>
  );
};

export const LandingTitle = ({ text, ...rest }) => {
  return (
    <Text
      as="h2"
      font="Semibold"
      size={{ _: "2rem", desktop: "3rem" }}
      color="#fff"
      {...rest}
    >
      {text}
    </Text>
  );
};
