import GradientButtonBlue from "../buttons/GradientButtonBlue";

export default function GradientButtonYellow({
  onClick,
  children,
  disabled,
  ...rest
}) {
  return (
    <GradientButtonBlue
      onClick={onClick}
      h={45}
      fontFamily="Lufga-Medium"
      gradientTo="#F9830D"
      gradientFrom="#FFBB06"
      hoverOutline={!disabled && "1px solid #FFBB06"}
      hoverColor={!disabled && "#FFBB06"}
      disabled={disabled}
      {...rest}
    >
      {children}
    </GradientButtonBlue>
  );
}
