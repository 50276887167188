import { useState } from "react";
import { useRouter } from "next/router";
import { menu } from "src/data/menus";
import styled, { Box } from "@xstyled/styled-components";
import Icon from "@/UI/Icon";
import { Chevron } from "@/UI/icons";
import Button from "@/UI/Button";
import { announcementPopoverAtom } from "src/store";
import { useAtom } from "jotai";
import { FlexBox } from "@/UI/Containers";

const Menu = styled.div`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5rem;
  list-style: none;
  text-decoration: none;
  width: 100vw;

  &:hover {
    #Chevron {
      svg path {
        fill: #feb806;
      }
    }
  }
`;

const MenuLabel = styled.span`
  margin-left: 1rem;
  font-family: Lufga-Regular;
  font-size: 1rem;
  line-height: 1.8;

  &:hover {
    cursor: pointer;
    color: #feb806;
  }
  @media (min-width: sm_mobile) {
    line-height: 2.2;
  }
`;

export default function MobileMenu({ setOpen }) {
  const router = useRouter();

  const [itemIndex, setItemIndex] = useState(null);
  const [show, setShow] = useAtom(announcementPopoverAtom);

  const goToPage = (page) => {
    if (page === "roadmap") {
      setOpen(false);
      router.push("/");
      setTimeout(function () {
        const section = document.getElementById("roadmap");

        if (section) {
          window.scrollTo({ top: section?.offsetTop, behavior: "smooth" });
        }
      }, 1000);
    } else if (page === "announcement") {
      setOpen(false);
      setShow(true);
    } else {
      setOpen(false);
      router.push(page);
      if (page === "/about/utility") {
        window.fbq("trackCustom", "learn");
        setOpen(false);
        router.push(page);
      }
    }
  };

  return (
    <Box
      as="aside"
      mt={12}
      // position="absolute"
      // top={60}
      bg="#041a24"
    >
      {menu.map((page, index) => {
        const subnavOpen = itemIndex === index;
        return (
          <Box key={page.title}>
            <Menu>
              <Button
                color="#fff"
                w="100%"
                justifyContent="space-between"
                hoverColor="#FEB806"
                focusColor="#FEB806"
                focusTextDecoration="underline #feb806"
                onClick={() =>
                  page.subnav
                    ? setItemIndex(subnavOpen ? null : index)
                    : goToPage(page.path)
                }
              >
                <MenuLabel>{page.title}</MenuLabel>
                {page?.subnav && (
                  <Icon
                    id="Chevron"
                    position="absolute"
                    right="2rem"
                    top={subnavOpen ? "1.4rem" : "0.75rem"}
                    transform={
                      subnavOpen ? "rotate(180deg) scale(1.6)" : "scale(1.6)"
                    }
                  >
                    <Chevron />
                  </Icon>
                )}
              </Button>
            </Menu>
            {subnavOpen && (
              <FlexBox
                dir="column"
                align="flex-start"
                position="absolute"
                bg="#041a24"
                w="100%"
                zIndex={10}
              >
                {page?.subnav?.map((subpage) => {
                  return (
                    <Button
                      key={subpage.title}
                      display="flex"
                      height="40px"
                      pl="4.5rem"
                      bg="transparent"
                      border="none"
                      color="#fff"
                      width="100%"
                      onClick={() => goToPage(subpage.path)}
                    >
                      <MenuLabel>{subpage.title}</MenuLabel>
                    </Button>
                  );
                })}
              </FlexBox>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
