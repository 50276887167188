import { useRouter } from "next/router";
import { useSetAtom } from "jotai";
import { balanceViewAtom, menuAtom } from "src/store";
import styled from "@xstyled/styled-components";
import { FlexBox } from "../Containers";
import Button from "../Button";

const Paragraphs = styled.divBox`
  width: 100%;
  white-space: normal;

  p {
    margin: 0;
    font-size: 0.875rem;
  }
`;

export default function TokenInfoDropdown({ isPolygon }) {
  const router = useRouter();

  const setMobileMenuOpen = useSetAtom(menuAtom);
  const setBalanceView = useSetAtom(balanceViewAtom);

  return (
    <FlexBox
      dir="column"
      position="absolute"
      top={42}
      w="100%"
      p="20px 16px"
      bg={isPolygon ? "#5E28B9" : "#F9830D"}
      borderRadius={6}
    >
      {isPolygon ? (
        <Paragraphs spaceY={10}>
          <p>
            WOF Tokens on the Polygon chain are earned from racing and leasing.
          </p>
          <p>
            Paid race winnings are sent out automatically. <br /> Free race
            winnings are sent to owners every 12 hours.
          </p>
          <p>
            Winnings earned from free races while using leased vehicles are
            locked for 2 weeks. <br /> After 2 weeks winnings will automatically
            be moved to your unclaimed balance.
          </p>
          <p>
            It is possible to claim your locked earnings early, which will
            result in a 35% fee to WOF Labs.
          </p>
          <p>
            Claiming unlocked tokens will not include a fee (excluding
            transaction fee).
          </p>
        </Paragraphs>
      ) : (
        <Paragraphs spaceY={10}>
          <p>
            WOF Tokens earned on the Ethereum chain are earned passively from
            owning World of Freight NFTs.
          </p>
          <p>Each owned NFT will earn you 25 $WOF a day.</p>
          <p>
            It is possible to claim your passively earned tokens using the
            Ethereum chain, but the tokens will be transferred on the Polygon
            chain.
          </p>
          <p>Claiming can take up to an hour.</p>
        </Paragraphs>
      )}
      <Button
        onClick={() => {
          setBalanceView({ shown: false, hovered: false });
          setMobileMenuOpen(false);
          router.push("/wof-token");
        }}
        p={0}
        m="16px auto 0"
        color="white"
        fontFamily="Lufga-Medium"
        fontSize="0.875rem"
        textDecoration="underline"
      >
        About $WOF
      </Button>
    </FlexBox>
  );
}
