export const MyFleetNew = ({ stroke }) => {
  return (
    <svg
      id="Group_4752"
      data-name="Group 4752"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_4751" data-name="Group 4751">
        <circle
          id="Ellipse_473"
          data-name="Ellipse 473"
          cx="11.5"
          cy="11.5"
          r="11.5"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1425"
          data-name="Path 1425"
          d="M17.775,11.041A4.128,4.128,0,0,1,13.494,15.2H10.8v3.892H8.062V6.883h5.432A4.128,4.128,0,0,1,17.775,11.041Zm-2.813,0a1.513,1.513,0,0,0-1.521-1.575H10.8v3.15h2.636A1.523,1.523,0,0,0,14.962,11.041Z"
          transform="translate(0.374 -0.787)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export const GarageNew = ({ stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_4749" data-name="Group 4749">
        <circle
          id="Ellipse_472"
          data-name="Ellipse 472"
          cx="11.5"
          cy="11.5"
          r="11.5"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1424"
          data-name="Path 1424"
          d="M14,23.333v-8.55A5.011,5.011,0,0,0,14,5.6v3.59a2,2,0,0,1-4,0V5.6a5.011,5.011,0,0,0,0,9.18v8.55"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export const JunkyardNew = ({ stroke }) => {
  return (
    <svg
      id="Group_4791"
      data-name="Group 4791"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_4758" data-name="Group 4758">
        <circle
          id="Ellipse_477"
          data-name="Ellipse 477"
          cx="11.5"
          cy="11.5"
          r="11.5"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1429"
          data-name="Path 1429"
          d="M18.03,10.62a6.03,6.03,0,0,0-6.622-6,6.2,6.2,0,0,0-5.438,6.26v4.36a1.767,1.767,0,0,0,1.767,1.767H8.756v1.469a.937.937,0,0,0,.936.936h4.616a.937.937,0,0,0,.936-.936V17.006h1.019a1.767,1.767,0,0,0,1.767-1.767Z"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_532"
          data-name="Line 532"
          x2="1.548"
          y2="1.548"
          transform="translate(8.264 10.071)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_533"
          data-name="Line 533"
          x1="1.548"
          y2="1.548"
          transform="translate(8.264 10.071)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_534"
          data-name="Line 534"
          x2="1.548"
          y2="1.548"
          transform="translate(13.746 10.071)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_535"
          data-name="Line 535"
          x1="1.548"
          y2="1.548"
          transform="translate(13.746 10.071)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1430"
          data-name="Path 1430"
          d="M10.694,14.717l1.386-1.386,1.411,1.412"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_536"
          data-name="Line 536"
          y1="2.42"
          transform="translate(10.988 16.836)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_537"
          data-name="Line 537"
          y1="2.42"
          transform="translate(13.038 16.836)"
          fill="none"
          stroke={stroke || "#ddd"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export const FleetDrivers = ({ stroke }) => {
  return (
    <svg
      id="Group_5511"
      data-name="Group 5511"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_5510"
        data-name="Group 5510"
        fill="none"
        stroke={stroke || "#ddd"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <circle
          id="Ellipse_517"
          data-name="Ellipse 517"
          cx="11.5"
          cy="11.5"
          r="11.5"
          transform="translate(0.5 0.5)"
        />
        <circle
          id="Ellipse_518"
          data-name="Ellipse 518"
          cx="6.762"
          cy="6.762"
          r="6.762"
          transform="translate(5.159 5.337)"
        />
        <path
          id="Path_4556"
          data-name="Path 4556"
          d="M13.339,13.971H10.5a1.176,1.176,0,0,1-1.115-.8L8.92,11.777a1.176,1.176,0,0,1,1.115-1.551h3.772a1.176,1.176,0,0,1,1.115,1.551l-.468,1.392A1.177,1.177,0,0,1,13.339,13.971Z"
        />
        <line
          id="Line_631"
          data-name="Line 631"
          y1="3.54"
          x2="2.229"
          transform="translate(7.587 13.75)"
        />
        <line
          id="Line_632"
          data-name="Line 632"
          x1="2.229"
          y1="3.54"
          transform="translate(14.078 13.75)"
        />
        <line
          id="Line_633"
          data-name="Line 633"
          x1="3.652"
          y2="0.454"
          transform="translate(5.386 10.709)"
        />
        <line
          id="Line_634"
          data-name="Line 634"
          x2="3.652"
          y2="0.454"
          transform="translate(14.836 10.709)"
        />
      </g>
    </svg>
  );
};

export const Checked = ({ stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.752"
      height="9.764"
      viewBox="0 0 14.752 9.764"
    >
      <path
        id="Path_4544"
        data-name="Path 4544"
        d="M4.495,9.754l4.573,4.573,7.35-7.35"
        transform="translate(-3.08 -5.563)"
        fill="none"
        stroke={stroke || "#181e22"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export const LeasingFleet = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g
        id="Group_5960"
        data-name="Group 5960"
        transform="translate(0 0.291)"
        fill="none"
        stroke="#ddd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <circle
          id="Ellipse_551"
          data-name="Ellipse 551"
          cx="19.027"
          cy="19.027"
          r="19.027"
          transform="translate(0.827 0.827)"
        />
        <path
          id="Path_4805"
          data-name="Path 4805"
          d="M23.158,27.424H6.988V5.165H19.273c1.517,1.516,2.368,2.368,3.885,3.883Z"
          transform="translate(4.574 3.381)"
        />
        <line
          id="Line_774"
          data-name="Line 774"
          x2="8.91"
          transform="translate(15.308 14.434)"
        />
        <line
          id="Line_775"
          data-name="Line 775"
          x2="3.668"
          transform="translate(20.549 26.674)"
        />
        <line
          id="Line_776"
          data-name="Line 776"
          x2="3.668"
          transform="translate(15.308 17.61)"
        />
      </g>
    </svg>
  );
};
